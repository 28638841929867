<template>
  <div>
    <AddHeaderTitle 
    :Header="Header" 
    />
    <AppHeaderInfoAdminList 
    :infos="infos" 
    />
    <b-card ref="formContainer">
      <b-table
      :fields="fields"
      :items="users"
      :small="small"
      >
        <!-- Column: User -->
        <template #cell(user)="admin">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :text="getAvatar(admin.item.firstname)"
                variant="light-warning"
                size="32"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ admin.item.firstname }} {{ admin.item.lastname }}
            </b-link>
            <small class="text-muted"><feather-icon icon="TagIcon" class="mr-25"/>{{ admin.item.nickname }}</small>        
          </b-media>
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${getStatus(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getStatusText(data.item.status) }}
          </b-badge>
        </template>
        <!-- Column: inscription -->
        <template #cell(isComplete)="data">
          <b-row align-h=center>
            <b-col>
              <feather-icon
              :icon="getIcon(data.item.isComplete)"
              size="21"
              class="ml-3"
              :class="`text-${getColor(data.item.isComplete)}`"
              v-b-tooltip.hover.right="getText(data.item.isComplete)"
              />
            </b-col>
          </b-row>
        </template>
        <!-- Column: date connection -->
        <template #cell(lastConnection)="data">
          <div>
             {{ getDate(data.item.lastConnection)  }}
          </div>  
        </template>
        <template #cell(created_at)="data">
          <div>
             {{ getDateCreation(data.item.created_at)  }}
          </div>  
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { BTable, BCard, BRow,BLink, BMedia,BBadge,VBTooltip,BCol, BAvatar } from 'bootstrap-vue';
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import Ripple from 'vue-ripple-directive'
import AddHeaderTitle from '@/app/views/components/AppHeaderTitle.vue'
import moment from 'moment'
import  { Admin } from '@/mixins/admin.js'

export default {
  name:"AdminList",
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    AppHeaderInfoAdminList,
    BTable,
    BCard,
    AddHeaderTitle,
    BRow, 
    BLink, BMedia,
    BBadge,
    BCol,
    BAvatar,
  },
  data() {
    return {
      users:[],
      fields : [
        { key: 'user', label: 'UTILISATEUR' },
        { key: 'email', label: 'EMAIL' },
        { key: 'status', label: 'STATUT' },
        { key: 'isComplete', label: 'ETAT INSCRIPTION' },
        { key: 'lastConnection', label: 'DERNIERE CONNEXION' },
        { key: 'created_at', label: 'DATE DE CRÉATION' },
      ], 
      Header: {
			config: "Admin",
			listLink: "",
			list: "Utilisateur-liste",
			},
			infos: {
				title: "Liste des utilisateurs",
				content: "texte explicatif de la page",
			},
      small: true,
      statusArray: [{
        status: "1",
        badge : "warning",
        text: "Actif"
      },
      {
        status: "0",
        badge : "danger",
        text: "Inactif"
      }
      ],
      signInArray: [{
        status: true,
        icon : "CheckIcon",
        color: "primary",
        text: "Complet"
      },
      {
        status: false,
        icon : "XIcon",
        color: "danger",
        text: "Incomplet"
      }
      ],
    }
  },

  mixins:[Admin],
  mounted() {
    this.loadAllUsers()
  },
  methods: {
    async loadAllUsers() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
        });
      try {
        const response = await this.getAllUsers()
        if (response.status == 1) {
          this.users = response.datas
        } else {
          this.$sweetError("AF-52")
        }
        loader.hide()
      } catch {
        loader.hide()
        this.$sweetError("AF-52")
      }
    },
    getStatus(status) {
      const stat = this.statusArray.find(element => element.status == status);
      return stat.badge 
    },
    getStatusText(status) {
      const stat = this.statusArray.find(element => element.status == status);
      return stat.text 
    },
    getIcon(status) {
      const stat = this.signInArray.find(element => element.status == status);
      return stat.icon 
    },
     getColor(status) {
      const stat = this.signInArray.find(element => element.status == status);
      return stat.color 
    },
    getText(status) {
      const stat = this.signInArray.find(element => element.status == status);
      return stat.text 
    },
    getDate(date) {
      //return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      moment.locale('fr');
      return moment(date).startOf('day').fromNow()
    },
     getDateCreation(date) {
      moment.locale('fr');
      return moment(date).format('LL')
    },
    getAvatar(firstname) {
      const init = firstname.substr(0,2)
      return init
    },
    openModal() {
      this.modal = true
    },
    hide() {
      this.modal = false
    },
  },
}
</script>
